import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types'

const MultiCarousel = ({data}) =>
{
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    return (
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={1000}
            containerClass="carousel-container"
            arrows={false}
            itemClass="carousel-item-padding-40-px"
            >
                {data.map((image, index) => (
                   <div key={index} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img alt={image.alt} src={image.path} width="150" height="75"/>
                    </div>
                ))}
        </Carousel>
    )
} 




  

MultiCarousel.propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string,
        path: PropTypes.string
      })
    ),
  }

  export default MultiCarousel