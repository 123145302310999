import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import MultiCarousel from '../components/MultiCarousel'

const partners = [
  {
    "alt": "ashoka",
    "path": "img/partners/ashoka-logo-min.png"
  },
  {
    "alt": "canirac",
    "path": "img/partners/canirac-logo-min.png"
  },
  {
    "alt": "celular-clinic",
    "path": "img/partners/celular-clinic-logo-min.jpg"
  },  
  {
    "alt": "cetis-59",
    "path": "img/partners/cetis-59-logo-min.jpg"
  },
  {
    "alt": "global-honor",
    "path": "img/partners/global-honor-logo-min.png"
  },
  {
    "alt": "idom",
    "path": "img/partners/idom-logo-min.png"
  },
  {
    "alt": "pwc",
    "path": "img/partners/pwc-logo-min.png"
  },
  {
    "alt": "ricardo",
    "path": "img/partners/ricardo-logo-min.png"
  },
  {
    "alt": "torreon",
    "path": "img/partners/torreon-logo-min.png"
  },
  
];

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    
    <div id="header">
    <div className="container">
        <div className="row">
            <div className="col-md-6 mt centered">
                <h2>¡Recicla fácil y Gana!<br/> Descarga la app</h2>
                <hr style={{width: "100%"}}/>
                <h3>La nueva manera de reciclar desde casa</h3>
                <p className="mt">Con Reciclapp podrás ayudar al medio ambiente y obtener beneficios en múltiples comercios ¡Sumate al movimiento verde!</p>
                <a href='https://play.google.com/store/apps/details?id=com.rdiaz.reciclapp'>
                  <img alt='Disponible en Google Play' src='/img/google-play-badge.png' width="300" />
                </a>
                <a href='https://apps.apple.com/us/app/reciclapp/id1609894948?itsct=apps_box_link&itscg=30200'>
                  <img alt='Descárgalo en el Apple Store' src='/img/apple-store-badge.svg' width="250" />
                </a>
            </div>

            <div className="col-md-6 centered">
                <img src="/img/iphone-front.jpg" width="300" />
            </div>
        </div>
    </div>
    </div>


    <section className="section section--gradient">
      <div className="container mtb">
        <div className="row centered">
          <div className="col-md-4 mt">
            <img src="img/ico-05.png" alt="" width="90" />
            <h4>SEPARA</h4>
            <p>Separa tus reciclables</p>
          </div>


          <div className="col-md-4 mt">
            <img src="img/ico-07.png" alt="" width="110" />
            <h4>COMPACTA</h4>
            <p>Compacta tus reciclables. Nosotros vamos a tu casa y los llevamos a reciclaje</p>
          </div>


          <div className="col-md-4 mt">
            <img src="img/ico-06.png" alt="" width="90" />
            <h4>RECICLA Y GANA</h4>
            <p>Gana puntos y descuentos por ayudar al medio ambiente.</p>
          </div>
      </div>
    </div>
    </section>
    

  <section className="section section--gradient" style={{backgroundColor: "#f5f5f5"}}>
    <div className="container">
      <div className="row">
        <div className="column is-12">
            <h3 className="has-text-weight-semibold is-size-2 centered">
              Aliados
            </h3>
          <MultiCarousel data={partners}/>
        </div>
      </div>
    </div>
  </section>
  

    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-12">
              <div>
              
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2 centered">
                    Noticias recientes
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn-shadow" to="/blog">
                      Ver más
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`